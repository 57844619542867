import React from 'react';
import { Link } from 'gatsby';

const ScrollToLink = ({ children, to, ...rest }) => {
   return (
      <Link to={`/${to}`} {...rest}>
         {children}
      </Link>
   );
};

export default ScrollToLink;
