import { createGlobalStyle } from 'styled-components';
import AvenirNextWOFF from '../fonts/avenir-next.woff';
import AvenirNextWOFF2 from '../fonts/avenir-next.woff2';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap');
	@font-face {
		font-family: 'Avenir Next';
		font-style: normal;
		font-weight: normal;
		src: url(${AvenirNextWOFF2}) format('woff2'),
				url(${AvenirNextWOFF}) format('woff');

	}

	html, body {
		font-family: 'Karla', sans-serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		font-size: 16px;
		margin: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
      color: #4A5568;

		@media (min-width: 720px) {
         font-size: 18px;
		}

		* {
         box-sizing: border-box;
         list-style: none !important;
		}
	}

	body.stuck {
		height: 100vh;
		overflow-y: hidden;
		position: fixed;
		width: 100%;
	}

	h1, h2, h3 {
		font-family: 'Avenir Next', sans-serif;
		margin-bottom: 16px;
		margin-top: 0;
		color: #2D3748;
	}

	h1 {
		line-height: 1.2;
		font-size: 32px;

		@media (min-width: 720px) {
			font-size: 56px;
		}
	}

	h2 {
		line-height: 1.2;
		font-size: 26px;

		@media (min-width: 720px) {
			font-size: 40px;
		}
	}

	button, a {
		transition: all .2s ease;
	}

	p {
		margin: 0 0 1.3em;
		line-height: 1.6;

		a {
			color: inherit;
			font-weight: bold;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
`;

export default GlobalStyle;
