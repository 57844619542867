import React, { useContext } from 'react';
import styled from 'styled-components';
import Section from './section';
import Question from './question';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';
import { myContext } from './Wrapper.js';

const StyledFaq = styled(Section)`
   .content {
      display: flex;
      justify-content: space-between;
      flex-flow: column wrap;

      @media (min-width: 720px) {
         flex-flow: row wrap;
      }

      .column {
         width: 100%;

         @media (min-width: 720px) {
            width: calc(50% - 48px);
         }
      }

      .text-column {
         display: flex;
         flex-flow: column wrap;
         justify-content: space-between;
         text-align: center;

         @media (min-width: 720px) {
            text-align: left;
         }
      }
   }

   h2 {
      @media (min-width: 720px) {
         margin-top: 32px;
         font-size: 32px;
      }
   }

   .image-wrapper {
      margin-top: 48px;
      width: 80%;
      display: none;

      @media (min-width: 720px) {
         display: block;
      }
   }
`;

const Faq = () => {
   const context = useContext(myContext);
   const { locale } = context;

   const data = useStaticQuery(graphql`
      query {
         heroImage: file(relativePath: { eq: "sitting-cat.png" }) {
            childImageSharp {
               fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);

   return (
      <StyledFaq background="#fff" id="faq">
         <div className="column text-column">
            <div className="text">
               <h2>
                  <FormattedMessage id="faq.header" />
               </h2>
               <p>
                  <FormattedMessage id="faq.subheader" />{' '}
                  <a
                     href="mailto:affiliates@luckydays.com"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     affiliates@luckydays.com
                  </a>
               </p>
            </div>
            <div className="image-wrapper">
               <Img fluid={data.heroImage.childImageSharp.fluid} />
            </div>
         </div>
         <div className="column">
            <Question
               question={<FormattedMessage id="faq.q1" />}
               answer={<FormattedMessage id="faq.a1" />}
            />
            <Question
               question={<FormattedMessage id="faq.q2" />}
               answer={<FormattedMessage id="faq.a2" />}
            />
            <Question
               question={<FormattedMessage id="faq.q3" />}
               answer={<FormattedMessage id="faq.a3" />}
            />
            <Question
               question={<FormattedMessage id="faq.q4" />}
               answer={<FormattedMessage id="faq.a4" />}
            />
            <Question
               question={<FormattedMessage id="faq.q5" />}
               answer={<FormattedMessage id="faq.a5" />}
            />
            <Question
               question={<FormattedMessage id="faq.q6" />}
               answer={<FormattedMessage id="faq.a6" />}
            />
            <Question
               question={<FormattedMessage id="faq.q7" />}
               answer={<FormattedMessage id="faq.a7" />}
            />
            <Question
               question={<FormattedMessage id="faq.q8" />}
               answer={<FormattedMessage id="faq.a8" />}
            />
            <Question
               question={<FormattedMessage id="faq.q9" />}
               answer={<FormattedMessage id="faq.a9" />}
            />
            {/* <Question
               question="I have more questions, who do I ask?"
               answer="Please do not hesitate to contact us using the contact form or send us an email at affiliates@luckydays.com"
            /> */}
         </div>
      </StyledFaq>
   );
};

export default Faq;
