import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import SEO from '../components/seo';
import Modal from '../components/modal';
import Section from '../components/section';
import EnglishLogo from '../images/englishiGoLogo.png';
import iGOLogo from '../images/iGOLogo.png';
import { myContext } from './Wrapper.js';

const StyledTerms = styled.div`
   .section {
      display: none;
   }

   .text {
      max-width: 720px;
      width: 100%;
      margin: 0 auto;

      * {
         max-width: 100%;
         hyphens: auto;
      }

      h2 {
         margin-bottom: 16px;
      }

      .subheader {
         font-size: 16px;
         font-weight: bold;
         color: #a0aec0;
         margin-bottom: 24px;

         @media (min-width: 720px) {
            font-size: 18px;
            margin-bottom: 32px;
         }

         span {
            margin: 0 8px;
         }
      }

      h3 {
         font-size: 24px;
         margin-bottom: 20px;

         @media (min-width: 720px) {
            font-size: 32px;
         }
      }

      a {
         font-weight: bold;
         color: inherit;
         text-decoration: none;

         &:hover {
            text-decoration: underline;
         }
      }
   }

   .small-header {
      text-transform: uppercase;
      font-weight: bold;
      color: #a0aec0;
      margin-top: 3em;
      margin-bottom: 16px;
      font-size: 16px;
      display: block;

      @media (min-width: 720px) {
         margin-top: 64px;
      }
   }

   ul {
      list-style: dotted;
      margin: 1em 0 2em;
      padding: 0 0 0 20px;

      li {
         line-height: 1.6em;
         margin: 0 0 1em;
         padding: 0 0 0 1em;
         position: relative;
      }
   }

   ol {
      list-style-type: none;
      counter-reset: item;
      margin: 1em 0 2em;
      padding: 0;

      > li {
         list-style: none;
         counter-increment: item;

         > ol {
            > li {
               display: block;
               line-height: 1.6;
               margin: 0 0 1em;
               padding: 0 0 0 2.4em;
               position: relative;

               @media (min-width: 720px) {
                  padding: 0 0 0 3em;
               }

               &:before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  content: counters(item, '.');
                  font-weight: bold;
                  color: #a0aec0;
                  font-size: 16px;

                  @media (min-width: 720px) {
                     font-size: 18px;
                  }
               }

               > ol {
                  list-style: none;
                  padding: 0;
                  margin: 1em 0 2em;
                  counter-reset: item;

                  > li {
                     position: relative;
                     line-height: 1.6;
                     margin: 0 0 1em;
                     padding: 0 0 0 2em;
                     counter-increment: item;

                     @media (min-width: 720px) {
                        padding: 0 0 0 3em;
                     }

                     &:before {
                        content: counter(item, lower-alpha) ')';
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-weight: bold;
                        color: #a0aec0;
                        font-size: 16px;

                        @media (min-width: 720px) {
                           font-size: 18px;
                        }
                     }

                     > ol {
                        list-style: none;
                        padding: 0;
                        margin: 1em 0 2em;
                        counter-reset: item;

                        > li {
                           position: relative;
                           line-height: 1.6;
                           margin: 0 0 1em;
                           padding: 0 0 0 2em;
                           counter-increment: item;

                           @media (min-width: 720px) {
                              padding: 0 0 0 3em;
                           }

                           &:before {
                              content: counter(item, lower-roman) '.';
                              position: absolute;
                              top: 0;
                              left: 0;
                              font-weight: bold;
                              color: #a0aec0;
                              font-size: 16px;

                              @media (min-width: 720px) {
                                 font-size: 18px;
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }

   small {
      font-size: 0.8em;
      line-height: 1.6;
      color: #718096;
      margin-bottom: 1em;
      display: block;
   }
`;

const OntarioTerms = () => {
   const context = useContext(myContext);
   const { locale } = context;

   const [modalVisibleOntario, setModalVisibleOntario] = useState(false);

   const toggleModal = () => {
      modalVisibleOntario ? setModalVisibleOntario(false) : setModalVisibleOntario(true);
   };

   return (
      <>
            <div>
               <SEO title="Terms and Conditions | LuckyDays Affiliate Partner Program" />
               <StyledTerms>
                  <Section background="#F7FAFC">
                     <div className="text">
                        <h2>Guidelines for Affiliates on Ontario Gambling Advertising</h2>
                        <p className="subheader">
                           Version 1.0 <span>•</span> Last updated: February
                           2019
                        </p>
                        <p>
                           The following guidelines (the "Ontario Guidelines") are an addendum to the Luckydays
                           Affiliate Program - Terms & Conditions (the "Terms") Raging Rhino N.V. and you acting as an
                           affiliate (the "Affiliate") which regulates the relationship with the Affiliate, following the
                           coming into force of the Registrar’s Standards for Internet Gaming established by the
                           Alcohol and Gaming Commission of Ontario (the “AGCO”) pursuant to the Gaming Control
                           Act, 1992 (Ontario) (the "Standards"). The addendum is performed pursuant to introductory
                           paragraph of the Terms according to which the Affiliate agreed to be bound by the most
                           current version of the Terms. The Standards and the Gaming Control Act, 1992 (Ontario) are
                           collectively defined herein as the "Ontario Gambling Advertising Regulations". The Affiliate
                           shall read the Standards carefully to ensure that it understands its obligations as well as the
                           potential liabilities should it fail to comply with them.
                        </p>
                        <ol>
                           <li>
                              <span className="small-header">Article 1</span>
                              <h3>Effective Date and Scope of application</h3>
                              <ol>
                                 <li>
                                    The Ontario Guidelines will take effect and will be binding from the date of their publication
                                    on the website www.Luckydaysaffiliates.com (the "Effective Date") as updated from time to
                                    time.
                                 </li>
                                 <li>
                                    By continuing to operate with the Affiliate program and/or perform the activities subject of
                                    the Terms following the Effective Date, the Affiliate agrees to be bound to the Ontario
                                    Guidelines.
                                 </li>
                                 <li>
                                    The Ontario Guidelines will be applicable to the activities conducted towards persons in
                                    Ontario and/or in relation to www.luckydays.ca and any other sites run by any company in
                                    Luckydays group of companies that are registered with the AGCO and directed to persons in
                                    Ontario (the "Ontario Sites"). In case of discrepancies between the Terms and the Ontario
                                    Guidelines, the latter shall prevail with reference to the Ontario Sites.
                                 </li>
                                 <li>
                                    If the Affiliate does not accept to be bound to the Ontario Guidelines, then it shall
                                    immediately cease the activities subject of the Terms and it is its responsibility to terminate
                                    them in accordance with the modalities provided therein.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 2</span>
                              <h3>Affiliate Obligations:</h3>
                              <ol>
                                 <li>
                                    <ol>
                                       <li>
                                           The Affiliate shall provide persons in Ontario commercial messages exclusively aimed at
                                          informing persons in Ontario about the products and services offered by Luckydays on the
                                          Ontario Sites, and shall not promote any other sites associated with Luckydays to persons in
                                          Ontario.
                                       </li>
                                       <li>
                                           The Affiliate shall not promote the Ontario Sites to any persons who are not in Ontario.
                                       </li>
                                       <li>
                                          The Affiliate shall not engage in direct-to-consumer marketing, direct-to-consumer
                                          promotion, or player referral services related to online gambling sites that facilitate or
                                          accept wagers from persons in Ontario without an AGCO registration.
                                       </li>
                                       <li>
                                          The Affiliate shall not provide advertising, marketing materials and communications in
                                          Ontario that targets high-risk, underage or self-excluded persons to participate in gambling.
                                       </li>
                                       <li>
                                          Advertising, marketing materials and communications provided by the Affiliate in Ontario
                                          shall not include underage individuals, and shall not knowingly be communicated or sent to
                                          high-risk players.
                                       </li>
                                        <li>
                                           Advertising, marketing materials and communications provided by the Affiliate in Ontario
                                           shall not:
                                           <ol>
                                              <li>Be based on themes, or use language, intended to appeal primarily to minors.</li>
                                              <li>Contain cartoon figures, symbols, role models, and/or celebrity/entertainer
                                                 endorsers whose primary appeal is to minors.</li>
                                              <li>
                                                 Use individuals who are, or appear to be, minors to promote gambling.
                                              </li>
                                              <li>
                                                 Appear in media and venues, including on websites, and in digital or online media,
                                                 directed primarily to minors, or where most of the audience is reasonably expected to
                                                 be minors.
                                              </li>
                                                <li>
                                                   Exploit the susceptibilities, aspirations, credulity, inexperience or lack of knowledge
                                                   of all potentially high-risk persons, or otherwise extoll the virtues of gambling.
                                              </li>
                                                <li>
                                                   Entice or attract potentially high-risk players.
                                              </li>
                                           </ol>
                                        </li>
                                       <li>
                                          The Affiliate shall put precautions in place to limit marketing communications to all
                                          known high-risk players.
                                       </li>
                                       <li>
                                          All marketing activities, including advertising and promotions, in which the Affiliate
                                          engages shall be truthful, shall not mislead players or misrepresent products, and
                                          advertising, marketing materials and communications provided by the Affiliate in Ontario
                                          shall not:
                                          <ol>
                                             <li>
                                                Imply that gambling is required in order to fulfill family or social obligations or solve
                                                 personal problems.
                                             </li>
                                             <li>
                                                Promote gambling as an alternative to employment, as a financial investment, or as
                                                a requirement for financial security.
                                             </li>
                                             <li>
                                                Contain endorsements by well-known personalities that suggest that gambling has
                                                contributed to their success.
                                             </li>
                                             <li>
                                                Encourage gambling as a means of recovering past gambling or other financial
                                                losses.
                                             </li>
                                             <li>
                                                Be designed so as to make false promises or present winning as the probable
                                                outcome.
                                             </li>
                                             <li>
                                                Imply that chances of winning increase:
                                                <ol>
                                                   <li>
                                                      The longer one plays
                                                   </li>
                                                   <li>
                                                      The more one spends
                                                   </li>
                                                   <li>
                                                      Suggest that skill can influence the outcome (for games where skill is not a
                                                      factor)
                                                   </li>
                                                </ol>
                                             </li>
                                             <li>
                                                 Portray, suggest, condone or encourage gambling behaviour that is socially
                                                 irresponsible or could lead to financial, social or emotional harm
                                             </li>
                                             <li>
                                                Suggest that gambling can provide an escape from personal or professional
                                                problems
                                             </li>
                                             <li>
                                                Portray gambling as indispensable or as taking priority in life; for example, over
                                                family, friends or professional or educational commitments
                                             </li>
                                             <li>
                                                Suggest that gambling can enhance personal qualities, for example, that it can
                                                improve self-image or self-esteem, or is a way to gain control, superiority, recognition
                                                or admiration
                                             </li>
                                             <li>
                                                Suggest peer pressure to gamble nor disparage abstention
                                             </li>
                                             <li>
                                                Link gambling to seduction, sexual success or enhanced attractiveness
                                             </li>
                                             <li>
                                                Portray gambling in a context of toughness or link it to resilience or irresponsible
                                                play
                                             </li>
                                             <li>
                                                Suggest gambling is a rite of passage
                                             </li>
                                             <li>
                                                 Offer a product or promotion that is not reasonably attainable without incurring
                                                 substantial losses
                                             </li>
                                          </ol>
                                       </li>
                                    </ol>
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 3</span>
                              <h3>
                                 Display of logos:
                              </h3>
                              <ol>
                                 <li>
                                    Where the Affiliate displays the Luckydays logo, it shall display the logo of iGaming Ontario
                                    (“iGO”) as provided by Luckydays alongside the Luckydays logo. Luckydays will provide a set
                                    of logos, which may include other prescribed trade-marks, to the Affiliates (each, an “iGO
                                    Logo” and collectively, the “Logo Suite”).
                                 </li>
                                 <li>
                                    All placements of an iGO Logo must appear sufficiently visible in size so that it is clearly
                                    readable. The Affiliate shall not use an iGO Logo in a manner implying that iGO is in a joint
                                    venture, is sponsoring, co-organizing, or a collaborator of Luckydays or any entity related to
                                    Luckydays. The Affiliate is responsible for using the name of iGO and/or the iGO Logo
                                    correctly.
                                 </li>
                                 <li>
                                    Examples of the English iGO Logo and iGO Logo with tagline are as follows:
                                    <img src={EnglishLogo} alt='EnglishLogo'/>
                                    <p>and:</p>
                                    <img src={iGOLogo} alt="iGOLogo" />
                                 </li>
                                 <li>
                                    The Affiliate shall only utilize an iGO Logo from within the Logo Suite provided by Luckydays.
                                    If an iGO Logo is revised in the future, the new revision will be provided to Affiliates.
                                 </li>
                                 <li>
                                    When using an iGO Logo, the Affiliate shall adhere to the following brand standards:
                                    <ol>
                                       <li>
                                          The Affiliate shall always use artwork provided by Luckydays and never recreate or
                                          redraw an iGO Logo
                                       </li>
                                       <li>
                                          The Affiliate shall under no circumstances change the iGO Logo design and/or
                                          proportions
                                       </li>
                                       <li>
                                          The Affiliate shall only depict an iGO Logo in the designated colours and must not use the
                                          iGO logo in black and white.
                                       </li>
                                    </ol>
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 3</span>
                              <h3>
                                 Gambling Inducements, Bonuses and Credits:
                              </h3>
                              <ol>
                                 <li>
                                    The Affiliate shall not use advertising and marketing materials that communicate gambling
                                    inducements, bonuses and credits, except through the forms of direct advertising and
                                    marketing referred to in Clause 5 of the Terms. The Affiliate shall not send any such
                                    advertising and marketing communications without the prior written consent of Luckydays
                                    obtained in the manner set forth in Clause 5 of the Terms.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 4</span>
                              <h3>Our Rights and Remedies:</h3>
                              <ol>
                                 <li>
                                    In case of breach of the obligations set out in paragraph 3 or your negligence in
                                    performance the activities in accordance with the Ontario Guidelines, or failure to in any
                                    way meet your obligations hereunder, the following remedies will be available:
                                    <ol>
                                       <li>
                                          the right to suspend any Affiliate’s participation in the Affiliate program for such
                                          period as is required to investigate any activities of the Affiliate that may be in breach
                                          of the Ontario Gambling Advertising Regulations.
                                       </li>
                                       <li>
                                          the right to withhold any revenue share or amount due to the Affiliate arising from or
                                          relating to any specific campaign, traffic, content or activity conducted or created by
                                          the Affiliate under the Terms
                                       </li>
                                       <li>
                                          the right to claim the amount of fines and any further damage, including
                                          reputational damages, potentially suffered by Luckydays, the companies of its group
                                          and/or any of the individuals or companies instructed or employed by them, as direct or
                                          indirect consequence of the breach of the obligations set out in these Ontario
                                          Guidelines
                                       </li>
                                       <li>
                                          the right to immediately terminate the Terms with the Affiliate
                                       </li>
                                    </ol>
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 5</span>
                              <h3>Governing Law:</h3>
                              <ol>
                                 <li>
                                    The validity, construction and performance of the Ontario Guidelines and any claim, dispute
                                    or matter arising under or in connection to the Ontario Guidelines or its enforceability shall
                                    be governed and construed in accordance with the applicable provisions of Article 11 of the
                                    Terms.
                                 </li>
                              </ol>
                           </li>
                        </ol>
                     </div>
                  </Section>
                  {modalVisibleOntario && <Modal toggleModal={toggleModal} />}
               </StyledTerms>
            </div>
      </>
   );
};

export default OntarioTerms;
