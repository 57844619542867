import React, { useState } from 'react';
import styled from 'styled-components';

const StyledQuestion = styled.div`
   /* background-color: #fff; */
   /* padding: 32px; */
   /* border-radius: 8px; */
   /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05); */
   padding: 32px 0;
   cursor: pointer;
   /* transition: background-color 0.2s ease; */
   position: relative;
   width: 100%;
   max-width: 720px;
   border-bottom: 2px solid #edf2f7;

   &:last-child {
      border-bottom: 0;
   }

   &:after {
      content: '+';
      font-size: 24px;
      font-weight: bold;
      position: absolute;
      top: 32px;
      right: 0;
      color: #a0aec0;
      transition: color 0.2s ease;
   }

   &:hover {
      &:after {
         color: #718096;
      }
   }

   h3 {
      margin-bottom: 0;
      padding-right: 32px;
      font-size: 18px;
   }

   .answer {
      visibility: hidden;
      max-height: 0;
      opacity: 0;
      margin: 0;
      transition: all 0.2s ease;

      .visible & {
         background: red;
      }
   }

   &.visible {
      background: #fff;
      color: #333;

      &:after {
         content: '-';
         color: #333;
      }

      h3 {
         color: #00262b;
      }

      > .answer {
         visibility: visible;
         max-height: 100px;
         opacity: 1;
         margin-top: 16px;
      }
   }
`;

const Question = ({ question, answer }) => {
   const [visible, setVisible] = useState(false);

   const toggle = () => {
      visible ? setVisible(false) : setVisible(true);
   };

   return (
      <StyledQuestion onClick={toggle} className={visible ? 'visible' : ''}>
         <h3 className="question">{question}</h3>
         <p className="answer">{answer}</p>
      </StyledQuestion>
   );
};

export default Question;
