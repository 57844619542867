import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const StyledHeroSection = styled(BackgroundImage)`
   width: 100%;
   min-height: 400px;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   background-position: bottom right;
   background-size: cover;
   height: ${props => props.height};

   .content {
      position: relative;
      text-align: center;
      color: white;
      padding: 20px;

      h1 {
         color: white;

         .hide-on-big {
            display: inline;
         }

         @media (min-width: 720px) {
            .hide-on-big {
               display: none;
               visibility: hidden;
            }
         }
      }

      p {
         font-size: 18px;
         margin-bottom: 32px;
         color: #e2e8f0;

         .hide-on-small {
            display: none;
            visibility: hidden;
         }

         @media (min-width: 720px) {
            font-size: 20px;
            margin-bottom: 40px;

            .hide-on-small {
               display: inline;
            }
         }
      }

      .buttons {
         .button {
            width: 240px;
            margin-bottom: 16px;

            @media (min-width: 720px) {
               margin-bottom: 0;
            }
         }
      }
   }
`;

const HeroSection = ({ imageData, children, height }) => {
   return (
      <StyledHeroSection fluid={imageData} height={height}>
         <div className="content">{children}</div>
      </StyledHeroSection>
   );
};

export default HeroSection;
