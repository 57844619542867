import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from './button';
import ButtonLink from './buttonLink';
import HeroSection from './heroSection';
import { FormattedMessage } from 'react-intl';
import { myContext } from './Wrapper.js';

const Hero = ({ toggleModal }) => {
   const context = useContext(myContext);
   const { locale } = context;
   const data = useStaticQuery(graphql`
      query {
         heroImage: file(relativePath: { eq: "luckydays-beach.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);

   return (
      <HeroSection
         imageData={data.heroImage.childImageSharp.fluid}
         height="100vh"
      >
         <h1>
            <FormattedMessage id="hero.welcome1" />{' '}
            <br className="hide-on-big" />
            <FormattedMessage id="hero.welcome2" />
            <br />
            <FormattedMessage id="hero.welcome3" />
         </h1>
         <p>
            <FormattedMessage id="hero.welcomeText" />{' '}
            <br className="hide-on-small" />
            <FormattedMessage id="hero.hideOnSmall" />
         </p>
         <div className="buttons">
            <>
               <Button
                  primary
                  margin
                  text={<FormattedMessage id="hero.register" />}
                  onClick={() => toggleModal()}
               />
               <ButtonLink
                  margin
                  text={<FormattedMessage id="hero.contact" />}
                  to="mailto:affiliates@luckydays.com"
                  target="_blank"
                  rel="noopener noreferrer"
               />
            </>
         </div>
      </HeroSection>
   );
};

export default Hero;
