import React, { useContext } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Section from './section';
import Button from './button';
import { FormattedMessage } from 'react-intl';
import { myContext } from './Wrapper.js';

const StyledCommission = styled(Section)`
   position: relative;

   .content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      .image,
      .text {
         width: 100%;
      }

      .image {
         margin: 0 auto 32px;

         .gatsby-image-wrapper {
            max-width: 200px;
            margin: 0 auto;
         }
      }

      .button {
         margin-top: 16px;
      }

      h2 {
         margin-bottom: 16px;
      }
   }

   @media (min-width: 720px) {
      .content {
         text-align: left;

         .image,
         .text {
            width: calc(50% - 32px);
         }

         .image {
            margin: 0;

            .gatsby-image-wrapper {
               max-width: 100%;
               margin: 0 auto;
            }
         }
      }
   }
`;

const Commission = ({ toggleModal }) => {
   const context = useContext(myContext);
   const { locale } = context;
   const data = useStaticQuery(graphql`
      query {
         heroImage: file(relativePath: { eq: "dancing-cat.png" }) {
            childImageSharp {
               fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);

   return (
      <StyledCommission background="#fff" id="commission">
         <div className="image">
            <Img fluid={data.heroImage.childImageSharp.fluid} />
         </div>
         <div className="text">
            <h2>
               <FormattedMessage id="commission.header" />
            </h2>
            <p>
               <FormattedMessage id="commission.text1" />
            </p>
            <p>
               <FormattedMessage id="commission.text2" />
            </p>
            <Button
               primary
               text={<FormattedMessage id="commission.register" />}
               onClick={() => toggleModal()}
            />
         </div>
      </StyledCommission>
   );
};

export default Commission;
