/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Link from '../images/link.inline.svg';

const StyledTestimonial = styled.div`
   display: flex;
   width: 100%;
   text-align: left;
   margin: 25px;
   right: 80px;
   position: relative;
   flex-flow: column wrap;
   min-width: 321px;
   left: 8px;

   &:last-child {
      position: relative;
      &::after {
         position: absolute;
         content: '';
         right: -50px;
         width: 50px;
         height: 100%;
      }
   }

   @media (min-width: 769px) {
      min-width: 320px;
      left: 89px;
      width: calc(50% - 16px);
   }

   .wrapper {
      position: relative;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
         0 4px 6px -2px rgba(0, 0, 0, 0.05);

      &:after {
         top: 100%;
         left: 32px;
         border: solid transparent;
         content: ' ';
         height: 0;
         width: 0;
         position: absolute;
         pointer-events: none;
         border-color: rgba(255, 255, 255, 0);
         border-top-color: #fff;
         border-width: 16px;
         margin-left: -16px;
      }

      blockquote {
         line-height: 1.5;
         margin: 0;
         background: #fff;
         padding: 32px;
         border-radius: 8px;
         overflow: hidden;
         position: relative;

         p {
            position: relative;
            margin-bottom: 0;
         }

         /* &:before {
            content: '“';
            color: #fffaf0;
            font-family: 'Karla';
            font-weight: bold;
            font-size: 20em;
            line-height: 0;
            margin: 0;
            position: absolute;
            bottom: -32px;
            right: -32px;
            z-index: 0;
         } */
      }
   }

   .quoter {
      display: inline-flex;
      text-align: left;
      margin-top: 24px;
      font-weight: bold;
      font-size: 16px;
      color: #718096;
      text-decoration: none;
      align-items: center;

      svg {
         margin-left: 10px;
         height: 20px;
         width: 20px;
         color: #a0aec0;
      }

      &:hover {
         text-decoration: underline;

         svg {
            color: #718096;
         }
      }

      &.no-link:hover {
         text-decoration: none;
      }
   }
`;

const Testimonial = ({ quote, quoter, link = '' }) => {
   return (
      <StyledTestimonial>
         <div className="wrapper">
            <blockquote>
               <p>{quote}</p>
            </blockquote>
         </div>
         {link !== '' ? (
            <a href={link} target="_blank" className="quoter">
               {quoter} <Link />
            </a>
         ) : (
            <span className="quoter no-link">{quoter}</span>
         )}
      </StyledTestimonial>
   );
};

export default Testimonial;
