import React, { useState, useContext } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styled from 'styled-components';
import Logo from '../images/logo.inline.svg';
import Uk from '../images/ukflag.inline.svg';
import Th from '../images/thflag.inline.svg';
import Lang from '../images/lang.inline.svg';
import Button from './button';
import ScrollToLink from './scrollToLink';
import ButtonLink from './buttonLink';
import { FormattedMessage } from 'react-intl';
import { myContext } from './Wrapper.js';

const StyledHeader = styled.header`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 99;
   padding: 0 32px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background: ${props => (props.isScrolled ? '#fff' : 'none')};
   box-shadow: ${props =>
      props.isScrolled
         ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);'
         : 'none'};
   min-height: ${props => (props.isScrolled ? '71px' : '81px')};
   transition: all 0.2s ease;

   .logo-button {
      color: transparent;
      background: transparent;
      padding: 0;
      margin: 0;
      border: none;
   }

   .logo {
      display: flex;

      svg {
         color: ${props => (props.isScrolled ? '#2D3748' : '#fff')};
      }
   }

   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      .visible {
         opacity: 1;
      }

      .invisible {
         opacity: 0.3;
      }

      li {
         display: inline-block;
         margin: 0 16px;
         padding: 0;

         @media (max-width: 1100px) {
            margin: 8px;
         }

         a {
            font-size: 16px;
            font-weight: bold;
            color: ${props => (props.isScrolled ? '#718096' : '#CBD5E0')};
            text-decoration: none;
            display: flex;
            transition: color 0.2s ease;

            &:hover,
            &:active {
               color: ${props => (props.isScrolled ? '#2D3748' : '#fff')};
            }
         }
      }
   }

   // Dropdown menu for language selection

   .container {
      margin: 0;
      position: absolute;
      right: 245px;
      top: ${props => (props.isScrolled ? '12px' : '21px')};

      &.th {
         right: 240px;
      }
   }
   .dropdown {
      position: relative;
      ::selection {
         background-color: transparent;
      }
   }
   .dropdown-title {
      cursor: pointer;
      outline: none;
      svg {
         pointer-events: none;
         width: 40px;
         height: 40px;
         border: none;
         filter: ${props => (props.isScrolled ? 'none' : 'invert(100%)')};
      }
      &:hover {
         .dropdown-menu {
            clip: rect(0 auto auto 0);
            background: ${props => (props.isScrolled ? '#fff' : 'transparent')};
            border-radius: 0 0 8px 8px;
         }
         .dropdown-menu:after {
            transform: scaleY(100);
         }
         .dropdown-menu .dropdown-menu-item {
            opacity: 1;
         }
      }
   }
   .dropdown-menu {
      position: absolute;
      padding-top: 7px;
      margin: 0;
      list-style: none;
      left: -5px;
      clip: rect(0 auto 0 0);
      /* background-color red; */
      /* visibility hidden */
      padding: 18px 0 4px;
      transition {
         clip: 0.6s linear;
      }
   }

   .dropdown-menu-item {
      outline: none;
      cursor: pointer;
      opacity: 0;
      transition: opacity 1s ease;
      width: 50px;
      margin: 0;
      button {
         border: none;
         background: ${props => (props.isScrolled ? '#fff' : 'transparent')};
         cursor: pointer;
         border-radius: 8px;
         svg {
            border: 1px solid gray;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            pointer-events: none;
            filter: none;
         }
      }
   }
   .sign-in {
      background: ${props =>
         props.isScrolled ? '#E2E8F0' : 'rgba(255,255,255,0.2)'};
      color: ${props => (props.isScrolled ? '#2D3748' : '#fff')};

      &:hover {
         background: ${props =>
            props.isScrolled ? '#CBD5E0' : 'rgba(255,255,255,0.3)'};
      }
   }
   .button:last-child {
      margin-left: 16px;
   }
`;

const Header = ({ toggleModal }) => {
   const context = useContext(myContext);
   const { locale } = context;
   const { selectLanguage } = context;
   const [isScrolled, setIsScrolled] = useState(false);

   useScrollPosition(
      ({ currPos }) => {
         const isShown = currPos.y < 0;
         if (isShown !== isScrolled) {
            setIsScrolled(isShown);
         }
      },
      [isScrolled],
   );

   const onLangChange = e => {
      selectLanguage(e);
      localStorage.language = e.target.value;
      const x = localStorage.getItem('language');
   };

   return (
      <StyledHeader isScrolled={isScrolled}>
         <li>
            <ScrollToLink to="#top" className="logo">
               <Logo />
            </ScrollToLink>
         </li>
         <ul className="navigation">
            <li>
               <ScrollToLink to="#about">
                  <FormattedMessage id="header.why" />
               </ScrollToLink>
            </li>
            <li>
               <ScrollToLink to="#commission">
                  <FormattedMessage id="header.commission" />
               </ScrollToLink>
            </li>
            <li>
               <ScrollToLink to="#testimonials">
                  <FormattedMessage id="header.testimonials" />
               </ScrollToLink>
            </li>
            <li>
               <ScrollToLink to="#faq">
                  <FormattedMessage id="header.faq" />
               </ScrollToLink>
            </li>
            <li>
               <ScrollToLink to="#contact">
                  <FormattedMessage id="header.contact" />
               </ScrollToLink>
            </li>
         </ul>
         <div className={locale ==='th' ? 'container th' : 'container'}>
            <div className="dropdown">
               <div className="dropdown-title">
                  <Lang />
                  <ul className="dropdown-menu">
                     <li className="dropdown-menu-item">
                        <button value="en" onClick={onLangChange}>
                           <Uk />
                        </button>
                     </li>
                     <li
                        className="dropdown-menu-item"
                        value="th"
                        onClick={onLangChange}
                     >
                      <button value="th" onClick={onLangChange}>
                        <Th />
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div className="buttons">
            {' '}
            <ButtonLink
               small
               text={<FormattedMessage id="header.signIn" />}
               to="http://affiliates.luckydaysaffiliates.com/affiliates"
               target="_blank"
               className="sign-in"
            />
            <Button
               primary
               small
               text={<FormattedMessage id="header.register" />}
               onClick={() => toggleModal()}
            />
         </div>
      </StyledHeader>
   );
};

export default Header;
