import React, { useContext  } from 'react';
import styled from 'styled-components';
import Logo from '../images/logo.inline.svg';
import Button from './button';
import ButtonLink from './buttonLink';
import ScrollToLink from './scrollToLink';
import { myContext } from './Wrapper.js';
import { FormattedMessage } from 'react-intl';

const StyledFooter = styled.footer`
   padding: 32px;
   background: #234e52;
   color: #fff;

   .row {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      @media (min-width: 720px) {
         flex-flow: row wrap;
         justify-content: space-between;
      }
   }
   .second {
      margin-top: 32px;
      border-top: 2px solid #285e61;
      padding-top: 16px;

      .text {
         display: flex;
         justify-content: center;
         flex-flow: column wrap;
         width: 100%;

         @media (min-width: 720px) {
            flex-flow: row wrap;
            justify-content: space-between;
         }

         a {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
            font-size: 15px;
            margin-top: 16px;

            &:hover {
               text-decoration: underline;
            }

            @media (min-width: 720px) {
               margin-top: 0;
            }
         }
      }
   }

   .logo {
      display: flex;

      svg {
         color: white;
      }
   }

   .text {
      display: flex;
      align-items: center;

      p {
         margin-bottom: 0;
         font-size: 15px;
         font-weight: bold;
      }
   }

   .buttons {
      display: flex;
      flex-flow: column wrap;

      button,
      a {
         margin-top: 24px;
         display: inline-block;
         text-align: center;
      }

      @media (min-width: 720px) {
         flex-flow: row wrap;

         button,
         a {
            margin-left: 16px;
            margin-top: 0;
         }
      }
   }
`;

const Footer = ({ toggleModal, toggleTermsModal }) => {
   const context = useContext(myContext);
   const { locale } = context;

   const onClickHandler = () => {
      toggleTermsModal()
      localStorage.setItem('showTerms', true);
   }

   return (
      <StyledFooter>
         <div className="row">
            <ScrollToLink to="#top" className="logo">
               <Logo />
            </ScrollToLink>
            <div className="buttons">
               <ButtonLink
                  margin
                  small
                  text={<FormattedMessage id="footer.contact" />}
                  to="mailto:affiliates@luckydays.com"
                  target="_blank"
                  rel="noopener noreferrer"
               />
               <ButtonLink
                  small
                  text={<FormattedMessage id="footer.signIn" />}
                  to="http://affiliates.luckydaysaffiliates.com/affiliates"
                  target="_blank"
                  className="sign-in"
               />
               <Button
                  small
                  text={<FormattedMessage id="footer.register" />}
                  onClick={() => toggleModal()}
               />
            </div>
         </div>
         <div className="row second">
            <div className="text">
               <p>Copyright © LuckyDays {new Date().getFullYear()}</p>
               <Button
                  primary
                  text={<FormattedMessage id="footer.terms" />}
                  onClick={() => onClickHandler()}
               />
            </div>
         </div>
      </StyledFooter>
   );
};

export default Footer;
