import React, { useContext } from 'react';
import styled from 'styled-components';
import Section from './section';
import Emoji from './emoji';
import { FormattedMessage } from 'react-intl';

const StyledContact = styled(Section)`
   .text {
      max-width: 720px;
      text-align: center;
      margin: 0 auto;

      .icon {
         font-size: 100px;
         text-decoration: none;
         width: 100px;
         height: 100px;
         display: inline-flex;
         justify-content: center;
         align-items: center;
      }
   }
`;

const Contact = () => {
   return (
      <StyledContact background="#EDF2F7" id="contact">
         <div className="text">
            <h2>
               <FormattedMessage id="contact.header" />
            </h2>
            <p>
               <FormattedMessage id="contact.text1" />{' '}
               <a href="mailto:affiliates@luckydays.com">
                  {' '}
                  <FormattedMessage id="contact.linkText" />
               </a>{' '}
               <FormattedMessage id="contact.text2" />
            </p>
            <a
               className="icon"
               href="mailto:affiliates@luckydays.com"
               rel="noopener noreferrer"
            >
               <Emoji symbol="💌" label="letter with heart" />
            </a>
         </div>
      </StyledContact>
   );
};
export default Contact;
