import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import GlobalStyles from '../shared/globalStyles';
import Header from './header';
import Footer from './footer';
import SmallHeader from './smallHeader';


// Check if window is defined before calling
if (typeof window !== 'undefined') {
   // eslint-disable-next-line global-require
   require('smooth-scroll')('a[href*="#"', {
      offset: 61,
      speed: 800,
      speedAsDuration: true,
   });
}

const Layout = ({ children, toggleModal, toggleTermsModal }) => {
   const data = useStaticQuery(graphql`
      query SiteTitleQuery {
         site {
            siteMetadata {
               title
            }
         }
      }
   `);

   const showBigHeader = useMediaQuery({ query: '(min-width: 1224px)' });
   const showSmallHeader = useMediaQuery({ query: '(max-width: 1223px)' });

   return (
      <>
         <GlobalStyles />
         {showBigHeader && (
            <Header
               siteTitle={data.site.siteMetadata.title}
               toggleModal={toggleModal}
            />
         )}
         {showSmallHeader && <SmallHeader toggleModal={toggleModal} />}
         <main>{children}</main>
         <Footer toggleModal={toggleModal} toggleTermsModal={toggleTermsModal} />
      </>
   );
};

Layout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default Layout;







