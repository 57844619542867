import React, { useRef, useState, useEffect } from 'react';
import { useScroll } from 'react-use';
import styled from 'styled-components';
import Section from './section';
import div from './card';
import Testimonial from './testimonial';
import arrowLeft from '../images/arrow-left.png';
import arrowRight from '../images/arrow-right.png';
import { FormattedMessage } from 'react-intl';
import { window, document, exists } from 'browser-monads';
import testimonialsData from '../testimonials/testimonials.json';

exists(window, document);

const StyledTestimonials = styled(Section)`
   padding: 80px 0 0;

   .content {
      max-width: 100%;
   }

   .text {
      margin: 0 auto;
      padding: 32px;
      @media (min-width: 769px) {
         padding: 64px 32px 32px 32px;
         margin-left: 80px;
         width: 40em;
      }
   }

   .scrollButtons {
      width: 130px;
      position: absolute;
      display: flex;
      margin: auto 0 auto auto;
      right: 0;

      button {
         background: none;
         border: none;
         position: relative;
         bottom: 50px;
         right: 32px;
         &:focus {
            outline: none;
         }
      }

      img {
         position: relative;
         width: 100%;
         height: auto;
         &:hover {
            cursor: pointer;
         }
      }

      &.hide {
         display: none !important;
      }

      .buttonLeftOpacity {
         opacity: 0.6;
      }

      .buttonRightOpacity {
         opacity: 0.6;
      }
   }

   .cards {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      overflow-x: auto;
      scroll-behavior: smooth;
      width: 100%;
      height: 475px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for IE, Edge and Firefox */
      &::-webkit-scrollbar {
         display: none;
      }

      @media (min-width: 768px) {
         height: auto;
      }
   }
`;

const Testimonials = () => {
   const [show, setShow] = useState(true);
   const scrollRef = useRef(0);
   const { x } = useScroll(scrollRef);
   const [scrollPosition, setscrollPosition] = useState(0);
   const scroll = scrollOffset => {
      scrollRef.current.scrollLeft += scrollOffset;
   };

   const maxWidth =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

   useEffect(() => {
      setscrollPosition(x);
   }, [x]);

   const clickHandlerLeft = () => {
      if (x !== 0) {
         scroll(-1000);
      }
   };

   const clickHandlerRight = () => {
      scroll(1000);
   };

   useEffect(() => {
      window.addEventListener('touchstart', function userTouch() {
         setShow(false);
      });
   }, []);

   return (
      <StyledTestimonials background="#EDF2F7" id="testimonials">
         <div className="text">
            <h2>
               <FormattedMessage id="testimonials.header" />
            </h2>
            <p>
               <FormattedMessage id="testimonials.text" />
            </p>
         </div>
         <div className="buttons">
            {show ? (
               <>
                  <div className="scrollButtons" id="scrollButtons">
                     <button
                        className={
                           scrollPosition === 0
                              ? 'buttonLeftOpacity'
                              : 'buttonLeft'
                        }
                        type="button"
                        onMouseDown={() => clickHandlerLeft()}
                     >
                        <img src={arrowLeft} alt="Left Arrow" />
                     </button>
                     <button
                        className={
                           scrollPosition === maxWidth && scrollPosition !== 0
                              ? 'buttonRightOpacity'
                              : 'buttonRight'
                        }
                        type="button"
                        onMouseDown={() => clickHandlerRight()}
                     >
                        <img src={arrowRight} alt="Right Arrow" />
                     </button>
                  </div>
               </>
            ) : (
               <></>
            )}
         </div>
         <div className="cards" ref={scrollRef}>
            {testimonialsData.testimonials.map(item => (
               <Testimonial
                  key={item.quote}
                  quote={item.quote}
                  quoter={item.quoter}
                  link={item.link}
               />
            ))}
         </div>
      </StyledTestimonials>
   );
};

export default Testimonials;
