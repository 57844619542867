import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Menu from '../images/menu.inline.svg';
import Logo from '../images/logo.inline.svg';
import Close from '../images/close.inline.svg';
import ScrollToLink from './scrollToLink';
import Button from './button';
import ButtonLink from './buttonLink';
import Uk from '../images/ukflag.inline.svg';
import Th from '../images/thflag.inline.svg';
import Lang from '../images/lang.inline.svg';
import { FormattedMessage } from 'react-intl';
import { myContext } from './Wrapper.js';

const StyledSmallHeader = styled.header`
   background: #fff;
   padding: 12px 10px 12px 20px;
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 999;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-items: center;
   box-sizing: border-box;
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

   // Dropdown menu for language selection

   .container {
      margin: 0;
      position: absolute;
      right: 50px;
   }
   .dropdown {
      position: relative;
      ::selection {
         background-color: transparent;
      }
   }
   .dropdown-title {
      outline: none;
      width: 38px;
      height: 38px;
      svg {
         pointer-events: none;
         width: 100%;
         height: 100%;
         border: none;
      }
   }
   .dropdown-menu {
      position: absolute;
      padding: 22px 0 0 0;
      display: flex;
      margin: 0;
      list-style: none;
      clip: rect(0 auto 0 0);
      right: -10px;
      /* background-color red; */
      /* visibility hidden */
      transition {
         clip: 0.6s linear;
      }

      &.open {
         clip: rect(0 auto auto -20px);
         background: #fff;
         border-radius: 0 0 8px 8px;
      }
      &.open:after {
         transform: scaleY(100);
      }
      &.open .dropdown-menu-item {
         opacity: 1;
      }
   }

   .dropdown-menu-item {
      outline: none;
      opacity: 0;
      transition: opacity 1s ease;
      min-width: 48px;
      min-height: 48px;
      margin: -4px 2px 8px;
      button {
         border: none;
         background: #fff;
         border-radius: 8px;
         min-width: 38px;
         min-height: 38px;
         padding: 0;
         margin: 8px;
         svg {
            border: 1px solid gray;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            pointer-events: none;
         }
      }
   }

   .button:last-child {
      margin-left: 16px;
   }

   .logo {
      color: #4a5568;
      display: flex;
   }

   .menu-toggle {
      padding: 5px;

      svg {
         height: 30px;
         width: 30px;
      }
   }

   .menu-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9990;
      height: 0;
      opacity: 0;
      overflow: hidden;
      /* transition: all 0s ease;
      transition-delay: 0.2s; */

      .menu-open & {
         height: auto;
         opacity: 1;

         .overlay {
            opacity: 1;
         }

         .menu {
            transform: translateX(0);
         }
      }

      .overlay {
         background: rgba(0, 0, 0, 0.8);
         position: absolute;
         top: 0;
         left: 0;
         bottom: 0;
         right: 0;
         opacity: 0;
         transition: opacity 0.2s ease;
      }

      .menu {
         background: #f7fafc;
         position: absolute;
         top: 0;
         right: 0;
         bottom: 0;
         width: 90%;
         max-width: 300px;
         padding: 72px 32px;
         z-index: 9991;
         transform: translateX(100%);
         transition: transform 0.2s ease;

         .close {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10px;
            border: none;
            font-size: 14px;
            font-weight: bold;
            outline: none;
            border-radius: 0 0 8px 0;
            transition: background-color 0.2s ease;
            background: none;

            &:hover {
               background-color: #edf2f7;
            }
         }

         .logo {
            margin-bottom: 20px;
            color: #4a5568;
         }

         ul {
            margin: 0;
            padding: 0;

            li {
               margin: 0;
               padding: 0;
               display: block;
               list-style: none;

               a {
                  padding: 20px 0;
                  border-bottom: 2px solid #edf2f7;
                  color: #a0aec0;
                  text-decoration: none;
               }
            }
         }
         .registerButtons {
            display: flex;
            margin-top: 20px;

            .sign-in {
               margin-right: 16px;
               background: #e2e8f0;
               color: #2d3748;
            }
         }
      }

      a {
         display: block;
         color: #fff;
         font-weight: bold;
      }
   }
`;

const SmallHeader = ({ toggleModal }) => {
   const context = useContext(myContext);
   const { locale } = context;
   const { selectLanguage } = context;
   const [menuVisible, setMenuVisible] = useState(false);
   const [langVisible, setLangVisible] = useState(false);
   const node = useRef();

      const handleClick = e => {
         if (node.current.contains(e.target)) {
            return;
         }
         setLangVisible(false)
      };

        useEffect(() => {
           document.addEventListener('mousedown', handleClick);
           return () => {
              document.removeEventListener('mousedown', handleClick);
           };
        }, []);

   const toggleLang = () => {
      if (langVisible) {
         setLangVisible(false);
      } else {
         setLangVisible(true);
      }
   };

   const toggleMenu = () => {
      if (menuVisible) {
         document.body.classList.add('stuck', 'menu-open');
         setMenuVisible(false);
      } else {
         document.body.classList.remove('stuck', 'menu-open');
         setMenuVisible(true);
      }
   };

   const onLangChange = e => {
      selectLanguage(e);
      localStorage.language = e.target.value;
      const x = localStorage.getItem('language');
   };

   return (
      <StyledSmallHeader>
         <ScrollToLink to="#top" className="logo">
            <Logo />
         </ScrollToLink>
         <div className="container">
            <div className="dropdown">
               <div className="dropdown-title" onClick={toggleLang} ref={node}>
                  <Lang />
                  <ul
                     className={
                        !langVisible ? 'dropdown-menu' : 'dropdown-menu open'
                     }
                  >
                     <li className="dropdown-menu-item">
                        <button value="en" onClick={onLangChange}>
                           <Uk />
                        </button>
                     </li>
                     <li
                        className="dropdown-menu-item"
                        value="th"
                        onClick={onLangChange}
                     >
                        <button value="th" onClick={onLangChange}>
                           <Th />
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div
            className="menu-toggle"
            aria-hidden="true"
            role="button"
            onClick={() => toggleMenu()}
         >
            <Menu />
         </div>
         <div className="menu-wrapper">
            <div
               className="overlay"
               aria-hidden="true"
               role="button"
               onClick={() => toggleMenu()}
            ></div>
            <div className="menu">
               <button
                  className="close"
                  type="button"
                  onClick={() => toggleMenu()}
               >
                  <Close />
               </button>
               <ScrollToLink
                  to="#top"
                  className="logo"
                  onClick={() => toggleMenu()}
               >
                  <Logo />
               </ScrollToLink>
               <ul className="navigation">
                  <li>
                     <ScrollToLink onClick={() => toggleMenu()} to="#about">
                        <FormattedMessage id="header.why" />
                     </ScrollToLink>
                  </li>
                  <li>
                     <ScrollToLink
                        onClick={() => toggleMenu()}
                        to="#commission"
                     >
                        <FormattedMessage id="header.commission" />
                     </ScrollToLink>
                  </li>
                  <li>
                     <ScrollToLink
                        onClick={() => toggleMenu()}
                        to="#testimonials"
                     >
                        <FormattedMessage id="header.testimonials" />
                     </ScrollToLink>
                  </li>
                  <li>
                     <ScrollToLink onClick={() => toggleMenu()} to="#faq">
                        <FormattedMessage id="header.faq" />
                     </ScrollToLink>
                  </li>
                  <li>
                     <ScrollToLink onClick={() => toggleMenu()} to="#contact">
                        <FormattedMessage id="header.contact" />
                     </ScrollToLink>
                  </li>
               </ul>
               <div className="registerButtons">
                  <>
                     <ButtonLink
                        small
                        text={<FormattedMessage id="header.signIn" />}
                        to="http://affiliates.luckydaysaffiliates.com/affiliates"
                        target="_blank"
                        className="sign-in"
                        onClick={() => toggleMenu()}
                     />
                     <Button
                        primary
                        small
                        text={<FormattedMessage id="header.register" />}
                        onClick={() => {
                           toggleMenu();
                           toggleModal();
                        }}
                     />
                  </>
               </div>
            </div>
         </div>
      </StyledSmallHeader>
   );
};

export default SmallHeader;
