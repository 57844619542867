import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import { useEffectOnce } from 'react-use';
import Hero from '../components/hero';
import SEO from '../components/seo';
import About from '../components/about';
import Commission from '../components/commission';
import Testimonials from '../components/testimonials';
import Faq from '../components/faq';
import Contact from '../components/contact';
import Modal from '../components/modal';
import TermsModal from '../components/termsModal';
import Wrapper from '../components/Wrapper.js';
import OntarioTermsModal from "../components/ontarioTermsModal";

const Index = () => {
   const [modalVisible, setModalVisible] = useState(false);
   const toggleModal = () => {
      if (modalVisible) {
         setModalVisible(false);
         document.body.classList.remove('stuck');
      } else {
         document.body.classList.add('stuck');
         setModalVisible(true);
      }
   };

   const [termsModalVisible, setTermsModalVisible] = useState(false);
   const [termsModalVisibleOntario, setTermsModalVisibleOntario] = useState(false);

   const toggleTermsModal = () => {
      if (termsModalVisible) {
         setTermsModalVisible(false);
         document.body.classList.remove('stuck');
      } else {
         document.body.classList.add('stuck');
         setTermsModalVisible(true);
      }

   };
   const toggleTermsModalOntario = () => {
      if (termsModalVisibleOntario) {
         setTermsModalVisibleOntario(false);
         document.body.classList.remove('stuck');
      } else {
         document.body.classList.add('stuck');
         setTermsModalVisibleOntario(true);
         setTermsModalVisible(false);
      }
   };

   useEffectOnce(() => {
      if (
         previousPath === '/terms-and-conditions/' ||
         previousPath === '/terms-and-conditions'
      ) {
         localStorage.setItem('showTerms', true);
         localStorage.setItem('showTermsOntario', false);
         toggleTermsModal();
      }else if (
          previousPath === '/terms-and-conditions-ontario/' ||
          previousPath === '/terms-and-conditions-ontario'
      ) {
         localStorage.setItem('showTermsOntario', true);
         localStorage.setItem('showTerms', false);
         toggleTermsModalOntario();
      }
   });

   useEffect(() => {
      if (localStorage.showTerms === 'true') {
         toggleTermsModal();
      }
      if (localStorage.showTermsOntario === 'true') {
         toggleTermsModalOntario();
      }
   }, []);

   return (
      <Wrapper>
         <Layout toggleModal={toggleModal} toggleTermsModal={toggleTermsModal}>
            <SEO title="LuckyDays Affiliate Partner Program" />
            <Hero toggleModal={toggleModal} />
            <About />
            <Commission toggleModal={toggleModal} />
            <Testimonials />
            <Faq />
            <Contact />
            {modalVisible && (
               <Modal
                  toggleModal={toggleModal}
                  toggleTermsModal={toggleTermsModal}
                  toggleTermsModalOntario={toggleTermsModalOntario}
               />
            )}
            {termsModalVisible && (
               <TermsModal toggleTermsModal={toggleTermsModal} />
            )}
            {termsModalVisibleOntario && (
                <OntarioTermsModal toggleTermsModalOntario={toggleTermsModalOntario} />
            )}
         </Layout>
      </Wrapper>
   );
};

export default Index;
