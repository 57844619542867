import React from 'react';
import styled from 'styled-components';
import Section from './section';
import Card from './card';
import Emoji from './emoji';
import { FormattedMessage } from 'react-intl';

const StyledAbout = styled(Section)`
   position: relative;
   text-align: center;
   padding-top: 80px;
   padding-bottom: 100px;

   h2 {
      margin-bottom: 20px;
   }

   .gatsby-image-wrapper {
      position: absolute !important;
      width: 500px;
      bottom: -200px;
      left: 0;
      display: none;
   }

   .text {
      max-width: 720px;
      margin: 0 auto;
   }

   .cards {
      margin-top: 48px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .card {
         /* width: calc(1 / 3 * 100% - (1 - 1 / 3) * 32px); */
         width: 100%;
         margin-bottom: 32px;

         @media (min-width: 720px) {
            width: calc(1 / 2 * 100% - (1 - 1 / 2) * 32px);
         }

         @media (min-width: 1024px) {
            width: calc(1 / 3 * 100% - (1 - 1 / 3) * 32px);
         }

         .icon {
            font-size: 48px;
            margin-bottom: 16px;
            background: #feebc8;
            border-radius: 50vh;
            display: inline-block;
            width: 50px;
            height: 50px;
         }
      }
   }
`;

const About = () => {
   return (
      <StyledAbout id="about" background="#EDF2F7">
         <div className="text">
            <h2>
               <FormattedMessage id="about.header" />
            </h2>
            <p>
               <FormattedMessage id="about.text" />
            </p>
         </div>
         <div className="cards">
            <Card>
               <div className="icon">
                  <Emoji symbol="🎰" label="slot machine" />
               </div>
               <h3>
                  <FormattedMessage id="about.box1Header" />
               </h3>
               <p>
                  <FormattedMessage id="about.box1Text" />
               </p>
            </Card>
            <Card>
               <div className="icon">
                  <Emoji symbol="🚀" label="rocket" />
               </div>
               <h3>
                  <FormattedMessage id="about.box2Header" />
               </h3>
               <p>
                  <FormattedMessage id="about.box2Text" />
               </p>
            </Card>
            <Card>
               <div className="icon">
                  <Emoji symbol="🏁" label="finish flag" />
               </div>
               <h3>
                  <FormattedMessage id="about.box3Header" />
               </h3>
               <p>
                  <FormattedMessage id="about.box3Text" />
               </p>
            </Card>
         </div>
      </StyledAbout>
   );
};

export default About;
