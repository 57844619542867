import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import Close from '../images/close.inline.svg';
import { myContext } from './Wrapper.js';
import Terms from './terms';
import OntarioTerms from "./termsOntario";

const StyledTermsModal = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.8);
   display: flex;
   justify-content: center;
   align-items: flex-start;
   z-index: 9999;
   overflow-y: auto;
   padding: 64px 32px;
   width: 100%;

   h1 {
      font-size: 32px;
      text-align: center;
   }

   .modal-container {
      background: white;
      width: 100%;
      max-width: 640px;
      height: auto;
      padding: 0;
      border-radius: 8px;
      position: relative;

      .close {
         position: absolute;
         top: 0;
         right: 0;
         padding: 10px;
         border: none;
         font-size: 14px;
         font-weight: bold;
         cursor: pointer;
         outline: none;
         border-radius: 0 8px 0 8px;
         transition: background-color 0.2s ease;
         background: none;

         &:hover {
            background-color: #edf2f7;
         }
      }

      .text {
         text-align: left;
         padding: 0;

         @media (min-width: 720px) {
            padding: 32px 32px 16px;
         }

         h2 {
            font-size: 32px;
         }

         p {
            margin-bottom: 0;
         }
      }
   }
`;

const OntarioTermsModal = ({ toggleTermsModalOntario }) => {
   const context = useContext(myContext);
   const { locale } = context;
   const node = useRef();

   const handleClick = e => {
      if (node.current.contains(e.target)) {
         // inside click
         return;
      }
      // outside click
      toggleTermsModalOntario();
      localStorage.setItem('showTermsOntario', false);
   };

   useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
         document.removeEventListener('click', handleClick);
      };
   }, []);

      const onClickHandler = () => {
         toggleTermsModalOntario();
         localStorage.setItem('showTermsOntario', false);
      };

   return (
      <StyledTermsModal>
         <div className="modal-container" ref={node}>
            <button
               className="close"
               type="button"
               onClick={() => onClickHandler()}
            >
               <Close />
            </button>
            <OntarioTerms />
         </div>
      </StyledTermsModal>
   );
};

export default OntarioTermsModal;
