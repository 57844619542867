import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import English from '../lang/en.json';
import Thai from '../lang/th.json';
import { window, document, exists } from 'browser-monads';

exists (window, document)
export const myContext = React.createContext();

const local = window.localStorage.language || navigator.language;

let lang;
if (local === 'th') {
   lang = Thai;
} else {
   lang = English;
}

const Wrapper = props => {
   const [locale, setLocale] = useState(local);
   const [messages, setMessages] = useState(lang);
   const { children } = props;

   function selectLanguage(e) {
      const newLocale = e.target.value;
      setLocale(newLocale);
      if (newLocale === 'th') {
         setMessages(Thai);
      } else {
         setMessages(English);
      }
   }

   return (
      <myContext.Provider value={{ locale, selectLanguage }}>
         <IntlProvider messages={messages} locale={locale}>
            {children}
         </IntlProvider>
      </myContext.Provider>
   );
};

Wrapper.propTypes = {
   children: PropTypes.any.isRequired,
};

export default Wrapper;
