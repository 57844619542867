import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledButtonLink = styled.a`
   display: inline-block;
   background: rgba(255, 255, 255, 0.2);
   color: white;
   padding: 1em 1.8em;
   border-radius: 50vw;
   cursor: pointer;
   border: none;
   font-size: 18px;
   line-height: 22px;
   font-weight: bold;
   font-family: 'Karla', sans-serif;
   outline: none;
	text-decoration: none;

   &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
		background: rgba(255, 255, 255, 0.3);
   }

   ${props =>
      props.primary &&
      css`
         background: #00b5ce;
         box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);

         &:hover {
            background: #0097ac;
         }
      `}

   ${props =>
      props.small &&
      css`
         padding: 9px 16px;
         font-size: 16px;
      `}

	${props =>
      props.margin &&
      css`
         margin: 0 8px;
      `}
`;

const ButtonLink = ({ text, to, primary, margin, small, ...rest }) => {
   return (
      <StyledButtonLink
         href={to}
         primary={primary}
         small={small}
         margin={margin}
         className="button"
         {...rest}
      >
         {text}
      </StyledButtonLink>
   );
};

ButtonLink.propTypes = {
   to: PropTypes.string.isRequired,
};

export default ButtonLink;
