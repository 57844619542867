import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import SEO from '../components/seo';
import Modal from '../components/modal';
import Section from '../components/section';
import { myContext } from './Wrapper.js';
import TermsModal from "./termsModal";

const StyledTerms = styled.div`
   .section {
      display: none;
   }

   .text {
      max-width: 720px;
      width: 100%;
      margin: 0 auto;

      * {
         max-width: 100%;
         hyphens: auto;
      }

      h2 {
         margin-bottom: 16px;
      }

      .subheader {
         font-size: 16px;
         font-weight: bold;
         color: #a0aec0;
         margin-bottom: 24px;

         @media (min-width: 720px) {
            font-size: 18px;
            margin-bottom: 32px;
         }

         span {
            margin: 0 8px;
         }
      }

      h3 {
         font-size: 24px;
         margin-bottom: 20px;

         @media (min-width: 720px) {
            font-size: 32px;
         }
      }

      a {
         font-weight: bold;
         color: inherit;
         text-decoration: none;

         &:hover {
            text-decoration: underline;
         }
      }
   }

   .small-header {
      text-transform: uppercase;
      font-weight: bold;
      color: #a0aec0;
      margin-top: 3em;
      margin-bottom: 16px;
      font-size: 16px;
      display: block;

      @media (min-width: 720px) {
         margin-top: 64px;
      }
   }

   ul {
      list-style: dotted;
      margin: 1em 0 2em;
      padding: 0 0 0 20px;

      li {
         line-height: 1.6em;
         margin: 0 0 1em;
         padding: 0 0 0 1em;
         position: relative;
      }
   }

   ol {
      list-style-type: none;
      counter-reset: item;
      margin: 1em 0 2em;
      padding: 0;

      > li {
         list-style: none;
         counter-increment: item;

         > ol {
            > li {
               display: block;
               line-height: 1.6;
               margin: 0 0 1em;
               padding: 0 0 0 2.4em;
               position: relative;

               @media (min-width: 720px) {
                  padding: 0 0 0 3em;
               }

               &:before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  content: counters(item, '.');
                  font-weight: bold;
                  color: #a0aec0;
                  font-size: 16px;

                  @media (min-width: 720px) {
                     font-size: 18px;
                  }
               }

               > ol {
                  list-style: none;
                  padding: 0;
                  margin: 1em 0 2em;
                  counter-reset: item;

                  > li {
                     position: relative;
                     line-height: 1.6;
                     margin: 0 0 1em;
                     padding: 0 0 0 2em;
                     counter-increment: item;

                     @media (min-width: 720px) {
                        padding: 0 0 0 3em;
                     }

                     &:before {
                        content: counter(item, lower-alpha) ')';
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-weight: bold;
                        color: #a0aec0;
                        font-size: 16px;

                        @media (min-width: 720px) {
                           font-size: 18px;
                        }
                     }

                     > ol {
                        list-style: none;
                        padding: 0;
                        margin: 1em 0 2em;
                        counter-reset: item;

                        > li {
                           position: relative;
                           line-height: 1.6;
                           margin: 0 0 1em;
                           padding: 0 0 0 2em;
                           counter-increment: item;

                           @media (min-width: 720px) {
                              padding: 0 0 0 3em;
                           }

                           &:before {
                              content: counter(item, lower-roman) '.';
                              position: absolute;
                              top: 0;
                              left: 0;
                              font-weight: bold;
                              color: #a0aec0;
                              font-size: 16px;

                              @media (min-width: 720px) {
                                 font-size: 18px;
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }

   small {
      font-size: 0.8em;
      line-height: 1.6;
      color: #718096;
      margin-bottom: 1em;
      display: block;
   }
`;

const Terms = () => {
   const context = useContext(myContext);
   const { locale } = context;

   const [modalVisible, setModalVisible] = useState(false);

   const toggleModal = () => {
      modalVisible ? setModalVisible(false) : setModalVisible(true);
   };

   return (
      <>
            <div>
               <SEO title="Terms and Conditions | LuckyDays Affiliate Partner Program" />
               <StyledTerms>
                  <Section background="#F7FAFC">
                     <div className="text">
                        <h2>Terms And Conditions</h2>
                        <p className="subheader">
                           Version 1.0 <span>•</span> Last updated: February
                           2019
                        </p>
                        <p>
                           The Affiliate Program for LuckyDays (the “Affiliate
                           Program”) is provided by Raging Rhino N.V,
                           (hereinafter “the Company”), a Company incorporated
                           under the laws of Curacao and the Kahnawake Gaming
                           Commission with its office located at Abraham de
                           Veerstraat 9, Willemstad, Curacao.
                        </p>
                        <p>
                           By completing the Affiliate application to the
                           Affiliate Program and clicking “I have read and agree
                           to the Affiliate Terms and Conditions (the “Terms”)”
                           within the registration form, you (hereinafter the
                           “Affiliate”) hereby agree to participate in the
                           Affiliate Program and abide by all the Terms and
                           Conditions set out in this Agreement. The outlined
                           commission structure in Article 20 of this Agreement
                           shall be deemed to form an integral part thereof.
                           Company reserves the right to amend, alter, delete or
                           extend any provisions of this Agreement, at any time
                           and at its sole discretion, without giving any
                           advance notice to the Affiliate subject to the Terms
                           set out in this Agreement.
                        </p>
                        <p>
                           You shall be obliged to continuously comply with the
                           Terms of this Agreement, the general Terms and
                           Conditions and Privacy Policies of the websites Lucky
                           Days com (referred to as the “Company Website”), as
                           well as with any other rules and/or guidelines
                           brought forward from time to time. An agreement
                           between the Company and the Affiliate shall be coming
                           into effect on the date when the Affiliate
                           application is approved by Company.
                        </p>
                        <ol>
                           <li>
                              <span className="small-header">Article 1</span>
                              <h3>Purpose</h3>
                              <ol>
                                 <li>
                                    The Company is in the business of marketing,
                                    logistical and general support services in
                                    connection with remote gaming. The Company
                                    licensed by the government of Curaçao and
                                    the Kahnawake Gaming Commission to inter
                                    alia assume responsibility for the
                                    advertising, marketing and promotional
                                    aspects of the online gaming business
                                    carried out on Company Websites and as part
                                    of this function the Company is operating
                                    the LuckyDays Affiliate Program.
                                 </li>
                                 <li>
                                    The Affiliate maintains and operates one or
                                    more websites on the internet (hereinafter
                                    collectively referred to as “Affiliate
                                    Website”), and/or refers potential customers
                                    to Company Websites through other channels.
                                 </li>
                                 <li>
                                    This Agreement governs the Terms and
                                    Conditions which are related to the
                                    promotion of the Company Websites by the
                                    Affiliate, whereby the Affiliate will be
                                    paid a commission as defined in this
                                    Agreement depending on the traffic sent to
                                    the Company’s websites and as subject to the
                                    Terms in this Agreement.
                                 </li>
                                 <li>
                                    The definition of the term “Net Revenue” can
                                    be found within Article 20 of the Terms. In
                                    case of an introduction of another product,
                                    or group of products in the future, the
                                    Company reserves the right to use an
                                    individual definition of the term “Net
                                    Revenue” for each product.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 2</span>
                              <h3>Acceptance of an Affiliate:</h3>
                              <ol>
                                 <li>
                                    The Company shall evaluate the Affiliate
                                    application form hereby submitted and shall
                                    inform the applicant in writing (email)
                                    whether the membership is accepted or not.
                                    The Company reserves the right to refuse any
                                    registration in its sole and absolute
                                    discretion.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 3</span>
                              <h3>Qualifying Conditions:</h3>
                              <ol>
                                 <li>
                                    The Affiliate hereby warrants that he/she:
                                    <ol>
                                       <li>
                                          is of legal age in the applicable
                                          jurisdiction in order to agree to and
                                          to enter into an agreement.
                                       </li>
                                       <li>
                                          is competent and duly authorized to
                                          enter into binding agreements.
                                       </li>
                                       <li>
                                          is the proprietor of all rights,
                                          licenses and permits to market,
                                          promote and advertise Company Websites
                                          in accordance with the provisions of
                                          this Agreement.
                                       </li>
                                       <li>
                                          will comply with all applicable rules,
                                          laws and regulations in correlation
                                          with the promotion of Company
                                          Websites.
                                       </li>
                                       <li>
                                          fully understands and accepts the
                                          Terms of the Agreement.
                                       </li>
                                    </ol>
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 4</span>
                              <h3>
                                 Responsibilities and Obligations of the
                                 Company:
                              </h3>
                              <ol>
                                 <li>
                                    The Company shall provide the Affiliate with
                                    all required information and marketing
                                    material for the implementation of the
                                    tracking link.
                                 </li>
                                 <li>
                                    The Affiliate maintains and operates one or
                                    more websites on the internet (hereinafter
                                    collectively referred to as “Affiliate
                                    Website”), and/or refers potential customers
                                    to Company Websites through other channels.
                                 </li>
                                 <li>
                                    The Company shall administrate the turnover
                                    generated via the tracking links, record the
                                    net revenues and the total amount of
                                    commission earned via the link, provide the
                                    Affiliate with commission statistics, and
                                    handle all customer services related to the
                                    business. A unique tracking identification
                                    code will be assigned to all referred
                                    customers.
                                 </li>
                                 <li>
                                    The Company shall pay the Affiliate the
                                    amount due depending on the traffic
                                    generated subject to the Terms of this
                                    Agreement.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 5</span>
                              <h3>
                                 Responsibilities and Obligations of the
                                 Affiliate:
                              </h3>
                              <ol>
                                 <li>
                                    The Affiliate hereby warrants:
                                    <ol>
                                       <li>
                                          to use its best efforts to actively
                                          and effectively advertise, market and
                                          promote Company Websites as widely as
                                          possible in order to maximize the
                                          benefit to the parties and to abide by
                                          the guidelines of the Company as they
                                          may be brought forward from time to
                                          time and/or as being published online.
                                       </li>
                                       <li>
                                          to market and refer potential players
                                          to Company Websites at its own cost
                                          and expense. The Affiliate will be
                                          solely responsible for the
                                          distribution, content and manners of
                                          its marketing activities. All of the
                                          Affiliate’s marketing activities must
                                          be professional, proper and lawful
                                          under applicable laws and must be in
                                          accordance with this Agreement.
                                       </li>
                                       <li>
                                          to use only a tracking link provided
                                          within the scope of the Affiliate
                                          Program, otherwise no warranty
                                          whatsoever can be assumed for proper
                                          registration and sales accounting.
                                          Also, not to change or modify in any
                                          way any link or marketing material
                                          without prior written authorization
                                          from the Company.
                                       </li>
                                       <li>
                                          to be responsible for the development,
                                          the operation, and the maintenance of
                                          its website as well as for all
                                          material appearing on its website.
                                       </li>
                                    </ol>
                                 </li>
                                 <li>
                                    The Affiliate hereby warrants:
                                    <ol>
                                       <li>
                                          that it will not perform any act which
                                          is libellous, discriminatory, obscene,
                                          unlawful or otherwise unsuitable or
                                          which contains sexually explicit,
                                          pornographic, obscene or graphically
                                          violent materials.
                                       </li>
                                       <li>
                                          that it will not actively target any
                                          person who is under the legal age for
                                          gambling.
                                       </li>
                                       <li>
                                          that it will not actively target any
                                          jurisdiction where gambling and the
                                          promotion thereof is illegal.
                                       </li>
                                       <li>
                                          that it will not generate traffic to
                                          the Company Websites by illegal or
                                          fraudulent activity, particularly but
                                          not limited to by:
                                          <ol>
                                             <li>sending spam.</li>
                                             <li>incorrect meta tags.</li>
                                             <li>
                                                registering as a player or
                                                making deposits directly or
                                                indirectly to any player account
                                                through his tracker(s) for its
                                                own personal use and/or the use
                                                of its relatives, friends,
                                                employees or other third
                                                parties, or in any other way
                                                attempt to artificially increase
                                                the commission payable or to
                                                otherwise defraud the Company.
                                                Violation of this provision
                                                shall be deemed to be fraud and
                                                give the Company right to
                                                terminate the Agreement with
                                                immediate effect, to close any
                                                related Affiliate accounts and
                                                to retain any earnings of an
                                                Affiliate who is found to
                                                violate this point.
                                             </li>
                                          </ol>
                                       </li>
                                       <li>
                                          that it will not present Affiliate
                                          Website in any way that might evoke
                                          any risk of confusion with LuckyDays
                                          and/or the Company Websites and/or the
                                          Company and/or convey the impression
                                          that the Affiliate Website is partly
                                          or fully originated from LuckyDays
                                          and/or the Company.
                                       </li>
                                       <li>
                                          with exception of the marketing
                                          materials as may be forwarded by the
                                          Company and/or made available online
                                          through the website{' '}
                                          <a href="https://www.luckydaysaffiliates.com">
                                             https://www.luckydaysaffiliates.com
                                          </a>{' '}
                                          the Affiliate may not use “LuckyDays”
                                          or other terms, trademarks and other
                                          intellectual property rights that are
                                          vested in the Company or LuckyDays
                                          unless the Company or LuckyDays
                                          consents to such use in writing.
                                       </li>
                                       <li>
                                          that it will not purchase, attempt to
                                          register, or register keywords, search
                                          terms or other identifiers for use in
                                          any search engine, portal, sponsored
                                          advertising service or other search or
                                          referral service which are identical
                                          or similar to any of the Company’s
                                          websites and trademarks, trade names
                                          or otherwise include the word “Lucky
                                          Days” or variations thereof.
                                       </li>
                                       <li>
                                          that it will not use any of the
                                          Company Websites’ trademarks, trade
                                          names or otherwise include the word
                                          “LuckyDays” or variations that are
                                          confusingly similar, in affiliate
                                          urls. Brand names may not be used in a
                                          derivative url or subdomain.
                                       </li>
                                       <li>
                                          that it will only generate traffic to
                                          the Company Website through seo and
                                          ppc activities. Traffic originating
                                          from email, sms, native or any other
                                          direct marketing is not allowed unless
                                          this has been approved beforehand in
                                          writing by the LuckyDays Affiliate
                                          Team.
                                       </li>
                                       <li>
                                          that it will not use any of the Lucky
                                          Days characters for marketing
                                          purposes.
                                       </li>
                                       <li>
                                          it will make sure that all marketing
                                          is clear, correctly advertised and
                                          mention that T&amp;C’s Apply. An 18+
                                          message symbol together with a link to
                                          begambleaware.org or a local version
                                          will be included when promoting Lucky
                                          Days. When promoting a LuckyDays
                                          bonus it must include any wagering
                                          requirements, maximum bonus amount
                                          available, the minimum deposit needed
                                          to activate the bonus, any withdrawal
                                          limits attached to the bonus and to
                                          include “new customers only” if it’s a
                                          welcome bonus that is being marketed.
                                          Full promotional Terms and Conditions
                                          need to be available one click away.
                                       </li>
                                       <li>
                                          it will not give the impression that
                                          gambling can be a source of income or
                                          a way to pay off debts.
                                       </li>
                                       <li>
                                          if it induces damages in form of fines
                                          or penalties for the Company. Then it
                                          will be held responsible for these
                                          damages.
                                       </li>
                                       <li>
                                          failure to follow these Terms and
                                          Conditions will grant the LuckyDays
                                          Affiliate team the right to
                                          immediately terminate this Agreement.
                                       </li>
                                    </ol>
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 6</span>
                              <h3>Payment:</h3>
                              <ol>
                                 <li>
                                    The Company agrees to pay the Affiliate a
                                    commission based on the Net Revenue
                                    generated from new customers referred by the
                                    Affiliate’s website and/or other channel.
                                    New customers are those customers of the
                                    Company who do not yet have and have not had
                                    a betting account with any Company Website
                                    and who access the Affiliate Website via the
                                    tracking link and who properly register and
                                    make real money transfers at least
                                    equivalent to the minimum deposit into their
                                    LuckyDays account. The commission shall be
                                    deemed to be inclusive of value added tax or
                                    any other tax if applicable.
                                 </li>
                                 <li>
                                    The commission shall be a percentage of the
                                    Net Revenue in accordance with what is set
                                    out in the commission structures for the
                                    particular product. The calculation is
                                    product specific and it is set out in every
                                    product-specific commission structure.
                                 </li>
                                 <li>
                                    The commission is calculated at the end of
                                    each month and payments shall be performed
                                    by the end of the following calendar month,
                                    provided that the amount due exceeds €100
                                    for Bank Wire transfers and €100 for Skrill
                                    and Neteller (minimum threshold). If the
                                    balance due is less than the minimum
                                    threshold, it shall be carried over to the
                                    following month and shall be payable when
                                    the accrued balance exceeds the minimum
                                    threshold.
                                 </li>
                                 <li>
                                    Payment of commissions shall be made as per
                                    the payment method chosen by the Affiliate
                                    in the Affiliate account. If an error is
                                    made in calculating the commission, the
                                    Company reserves the right to correct such
                                    calculation at any time and will immediately
                                    pay out underpayment or reclaim overpayment
                                    made to the Affiliate.
                                 </li>
                                 <li>
                                    Acceptance of payment by the Affiliate shall
                                    be deemed to be full and the final
                                    settlement of the balance due for the period
                                    indicated.
                                 </li>
                                 <li>
                                    If the Affiliate disagrees with the balance
                                    due as reported, it shall within a period of
                                    thirty (30) days send an email to the
                                    Company to{' '}
                                    <a href="mailto:affiliates@luckydays.com">
                                       affiliates@luckydays.com
                                    </a>{' '}
                                    and indicate the reasons of such dispute.
                                    Failure to send an email within the
                                    prescribed time limit shall be deemed to be
                                    considered as an irrevocable acknowledgment
                                    of the balance due for the period indicated.
                                 </li>
                                 <li>
                                    The Company may delay payment of any balance
                                    to the Affiliate for up to one hundred and
                                    eighty (180) days, while it investigates and
                                    verifies that the relevant transactions
                                    comply with the provisions of the Terms.
                                 </li>
                                 <li>
                                    No payment shall be due when the traffic
                                    generated is illegal or contravenes any
                                    provision of these terms.
                                 </li>
                                 <li>
                                    The Affiliate agrees to return all
                                    commissions received based on fraudulent or
                                    falsified transactions, plus all costs for
                                    legal causes or actions that may be brought
                                    against the Affiliate to the fullest extent
                                    of the law.
                                 </li>
                                 <li>
                                    For the sake of clarity the parties
                                    specifically agree that upon termination of
                                    this Agreement by either party, the
                                    Affiliate shall no longer be entitled to
                                    receive any payment whatsoever from the
                                    Company, provided that payments already due
                                    (earned and unpaid commissions) shall be
                                    paid out.
                                 </li>
                                 <li>
                                    At the sole discretion of the Company the
                                    Affiliate may be given the opportunity to
                                    restructure its commission structure.
                                    Examples of alternative revenue sources
                                    could include a cost per acquisition (CPA)
                                    model. Once an Affiliate opts to accept the
                                    Company’s offer for a different revenue
                                    structure to the standard commission
                                    structure outlined in article 20, then the
                                    Affiliate is aware and hereby agrees and
                                    understands that the new proposed revenue
                                    structure shall replace his existing
                                    commission structure in its entirety.
                                    Notwithstanding the above, all the
                                    Affiliate’s obligations assumed under this
                                    present agreement will still continue to
                                    apply to the Affiliate up until the
                                    termination of this Agreement and thereafter
                                    in accordance with the Terms and Conditions
                                    contained in this Agreement.
                                 </li>
                                 <li>
                                    The Affiliate shall be exclusively
                                    responsible for the payment of any and all
                                    taxes, levies, fees, charges and any other
                                    money payable or due both locally and abroad
                                    (if any) to any tax authority, department or
                                    other competent entity by the Affiliate as a
                                    result of the revenue generated under this
                                    Agreement. The Company shall in no manner
                                    whatsoever be held liable for any amounts
                                    unpaid but found to be due by the Affiliate
                                    and the Affiliate hereby indemnifies the
                                    Company in that regard.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 7</span>
                              <h3>Termination:</h3>
                              <ol>
                                 <li>
                                    This Agreement may be terminated by either
                                    party by giving a thirty (30) day written
                                    notification to the other party. Written
                                    notification may be given by an email.
                                 </li>
                                 <li>
                                    The contracting parties hereby agree that
                                    upon the termination of this Agreement:
                                    <ol>
                                       <li>
                                          the Affiliate must remove all
                                          references to LuckyDays and/or its
                                          brands (including brands of its
                                          subsidiary companies) and/or the
                                          Company Websites from the Affiliate’s
                                          websites and/or other marketing
                                          channel and communications,
                                          irrespectively of whether the
                                          communications are commercial or
                                          non-commercial.
                                       </li>
                                       <li>
                                          all rights and licenses granted to the
                                          Affiliate under this Agreement shall
                                          immediately terminate and all rights
                                          shall revert to the respective
                                          licensors, and the Affiliate will
                                          cease the use of any trademarks,
                                          service marks, logos and other
                                          designations vested in the Company.
                                       </li>
                                       <li>
                                          the Affiliate will be entitled only to
                                          those earned and unpaid commissions as
                                          of the effective date of termination;
                                          however provided, the Company may
                                          withhold the Affiliate’s final payment
                                          for a reasonable time to ensure that
                                          the correct amount is paid. The
                                          Affiliate will not be eligible to earn
                                          or receive commissions after this
                                          date.
                                       </li>
                                       <li>
                                          if this Agreement is terminated by the
                                          Company on the basis of the
                                          Affiliate’s breach of terms or
                                          applicable laws, the Company shall be
                                          entitled to withhold the Affiliate’s
                                          earned but unpaid commissions as of
                                          the termination date as collateral for
                                          any claim arising from such breach. It
                                          is further specified that termination
                                          by the Company due to a breach by the
                                          Affiliate of any of the clauses in
                                          this Agreement shall not require a
                                          notice period and such termination
                                          shall have immediate effect upon
                                          simple notification by the Company to
                                          the Affiliate.
                                       </li>
                                       <li>
                                          the Affiliate must return to the
                                          Company any and all confidential
                                          information (and all copies and
                                          derivations thereof) in the
                                          Affiliate’s possession, custody and
                                          control.
                                       </li>
                                       <li>
                                          the Affiliate will release the Company
                                          from all obligations and liabilities
                                          occurring or arising after the date of
                                          such termination, except with respect
                                          to those obligations that by their
                                          nature are designed to survive
                                          termination. Termination will not
                                          relieve the Affiliate from any
                                          liability arising from any breach of
                                          this Agreement, which occurred prior
                                          to termination and/or to any liability
                                          arising from any breach of
                                          confidential information even if the
                                          breach arises at any time following
                                          the termination of this Agreement. The
                                          Affiliate’s obligation of
                                          confidentiality towards the Company
                                          shall survive the termination of this
                                          Agreement.
                                       </li>
                                       <li>
                                          The Affiliate is to be aware that any
                                          operation from its end which runs
                                          counter to this Agreement may result
                                          in very serious consequences for the
                                          Affiliate itself, for LuckyDays, or
                                          for both Parties, including without
                                          limitation, fines, penalties, breaches
                                          of license conditions and ability to
                                          do business, as well as potential
                                          civil and criminal action against the
                                          Affiliate or LuckyDays by the
                                          respective authorities. Without
                                          prejudice to any of the Company’s
                                          rights herein or at law, the Company
                                          may forthwith terminate this
                                          Agreement, in part or in its entirety,
                                          or any of your accounts should you act
                                          in breach of the foregoing and the
                                          Affiliate shall be held fully
                                          responsible and liable for any such
                                          resulting fine, penalty, claim,
                                          action, or loss which is caused to us
                                          as a result of your action or default
                                          as the case may be.
                                       </li>
                                    </ol>
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 8</span>
                              <h3>Warranties:</h3>
                              <ol>
                                 <li>
                                    The Affiliate expressly acknowledges and
                                    agrees that the use of the internet is at
                                    its risk and that this Affiliate Program is
                                    provided “as is” and “as available” without
                                    any warranties or conditions whatsoever,
                                    express or implied. No guarantee is made
                                    that it will make access to its website
                                    possible at any particular time or any
                                    particular location.
                                 </li>
                                 <li>
                                    The Company shall in no event be liable to
                                    the Affiliate or anyone else for any
                                    inaccuracy, error or omission in, or loss,
                                    injury or damage caused in whole or in part
                                    by failures, delays or interruptions of the
                                    Company Websites or the Affiliate Program.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 9</span>
                              <h3>Indemnification:</h3>
                              <ol>
                                 <li>
                                    The Affiliate agrees to defend, indemnify
                                    and hold the Company and its Affiliates,
                                    successors, officers, employees, agents,
                                    directors, shareholders and attorneys, free
                                    and harmless from and against any and all
                                    claims and liabilities, including reasonable
                                    attorneys’ and experts’ fees, related to or
                                    arising from:
                                    <ol>
                                       <li>
                                          any breach of the Affiliate’s
                                          representations, warranties or
                                          covenants under this Agreement.
                                       </li>
                                       <li>
                                          the Affiliate’s use (or misuse) of the
                                          marketing materials.
                                       </li>
                                       <li>
                                          all conduct and activities occurring
                                          under the Affiliate’s user id and
                                          password.
                                       </li>
                                       <li>
                                          any defamatory, libellous or illegal
                                          material contained within the
                                          Affiliate’s website or as part of the
                                          Affiliate’s information and data.
                                       </li>
                                       <li>
                                          any claim or contention that the
                                          Affiliate’s website or the Affiliate’s
                                          information and data infringes any
                                          third party’s patent, copyright,
                                          trademark, or other intellectual
                                          property rights or violates any third
                                          party’s rights of privacy or
                                          publicity.
                                       </li>
                                       <li>
                                          third party access or use of the
                                          Affiliate’s website or to the
                                          Affiliate’s information and data.
                                       </li>
                                       <li>
                                          any claim related to the Affiliate
                                          Website.
                                       </li>
                                       <li>any violation of this Agreement.</li>
                                    </ol>
                                 </li>
                                 <li>
                                    The Company reserves the right to
                                    participate, at its own expense in the
                                    defense of any matter.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 10</span>
                              <h3>Company Rights:</h3>
                              <ol>
                                 <li>
                                    The Company and/or LuckyDays may refuse any
                                    player or close a player’s account if it is
                                    necessary to comply with the Company’s
                                    and/or Lucky Day’s policy and/or protect the
                                    interest of the Company.
                                 </li>
                                 <li>
                                    The Company may refuse any applicant and/or
                                    may close any Affiliate’s account if it is
                                    necessary to comply with the Company’s
                                    policy and/or protect the interest of the
                                    Company. If the Affiliate is in breach of
                                    this Agreement or the Company’s terms or
                                    other rules, policies and guidelines of the
                                    Company, the Company may besides closing the
                                    Affiliate’s account take any other steps at
                                    law to protect its interest.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 11</span>
                              <h3>Governing Law &amp; Jurisdictions:</h3>
                              <ol>
                                 <li>
                                    This Agreement shall be governed and
                                    construed in accordance with the laws of
                                    Curaçao and any action or dispute relating
                                    to this Agreement must be brought in the
                                    Affiliate irrevocably consents to the
                                    jurisdiction of the Curaçao courts.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 12</span>
                              <h3>Assignment:</h3>
                              <ol>
                                 <li>
                                    The Affiliate may not assign this Agreement,
                                    by operation of law or otherwise, without
                                    obtaining the prior written consent of the
                                    Company. In the event that the Affiliate
                                    acquires or otherwise obtains control of
                                    another Affiliate of LuckyDays, then
                                    accounts will coexist on individual terms.
                                 </li>
                                 <li>
                                    The Company may assign this Agreement, by
                                    operation of the law or otherwise, at any
                                    time without obtaining the prior consent of
                                    the Affiliate.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 13</span>
                              <h3>Non-waiver:</h3>
                              <ol>
                                 <li>
                                    The Company’s failure to enforce the
                                    Affiliate’s adherence to the Terms outlined
                                    in this Agreement shall not constitute a
                                    waiver of the right of the Company to
                                    enforce said terms at any time.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 14</span>
                              <h3>Force Majeure:</h3>
                              <ol>
                                 <li>
                                    Neither party shall be liable to the other
                                    for any delay or failure to perform its
                                    obligations under this Agreement if such
                                    delay or failure arises from a cause beyond
                                    the reasonable control of and is not the
                                    fault of such party, including but not
                                    limited to labor disputes, strikes,
                                    industrial disturbances, acts of god, acts
                                    of terrorism, floods, lightning, utility or
                                    communications failures, earthquakes or
                                    other casualty. If a force majeure event
                                    occurs, the non-performing party is excused
                                    from whatever performance is prevented by
                                    the force majeure event to the extent
                                    prevented. Provided that, if the force
                                    majeure event subsists for a period
                                    exceeding thirty (30) days then either party
                                    may terminate the Agreement without notice.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 15</span>
                              <h3>Relationship of the Parties:</h3>
                              <ol>
                                 <li>
                                    Nothing contained in this Agreement, nor any
                                    action taken by any party to this Agreement,
                                    shall be deemed to constitute either party
                                    (or any of such party’s employees, agents,
                                    or representatives) an employee, or legal
                                    representative of the other party, nor to
                                    create any partnership, joint venture,
                                    association, or syndication among or between
                                    the parties, nor to confer on either party
                                    any express or implied right, power or
                                    authority to enter into any agreement or
                                    commitment on behalf of (nor to impose any
                                    obligation upon) the other party.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 16</span>
                              <h3>Severability / Waiver:</h3>
                              <ol>
                                 <li>
                                    Whenever possible, each provision of this
                                    Agreement shall be interpreted in such a
                                    manner as to be effective and valid under
                                    applicable law but, if any provision of this
                                    Agreement is held to be invalid, illegal or
                                    unenforceable in any respect, such provision
                                    will be ineffective only to the extent of
                                    such invalidity, or unenforceability,
                                    without invalidating the remainder of this
                                    Agreement. No waiver will be implied from
                                    conduct or failure to enforce any rights and
                                    must be in writing to be effective.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 17</span>
                              <h3>Confidentiality:</h3>
                              <ol>
                                 <li>
                                    All information, including but not limited
                                    to business and financial, lists of
                                    customers and buyers, as well as price and
                                    sales information and any information
                                    relating to products, records, operations,
                                    business plans, processes, product
                                    information, business know-how or logic,
                                    trade secrets, market opportunities and
                                    personal data of the Company shall be
                                    treated confidentially. Such information
                                    must not be used for own commercial or other
                                    purposes or divulged to any person or third
                                    party neither direct nor indirectly unless
                                    the prior explicit and written consent of
                                    the Company this provision shall survive the
                                    termination of this Agreement.
                                 </li>
                                 <li>
                                    The Affiliate obliges himself not to use the
                                    confidential information for any purpose
                                    other than the performance of its
                                    obligations under this Agreement.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 18</span>
                              <h3>Changes to this Agreement:</h3>
                              <ol>
                                 <li>
                                    The Company reserves the right to amend,
                                    alter, delete or add to any of the
                                    provisions of this Agreement, at any time
                                    and at its sole discretion, without giving
                                    any advance notice to the Affiliate subject
                                    to the Terms set out in this Agreement. Any
                                    such changes will be published on{' '}
                                    <a href="https://www.luckydaysaffiliates.com">
                                       https://www.luckydaysaffiliates.com
                                    </a>
                                    .
                                 </li>
                                 <li>
                                    In case of any discrepancy between the
                                    meanings of any translated versions of this
                                    Agreement, the english language version
                                    shall prevail.
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 19</span>
                              <h3>Trademarks:</h3>
                              <ol>
                                 <li>
                                    Nothing contained in this Agreement will
                                    grant the Affiliate any right, title or
                                    interest in the trademarks, trade names,
                                    service marks or other intellectual property
                                    rights [hereinafter referred to simply as
                                    ‘marks’] of the Company or LuckyDays. At no
                                    time during or after the term will Affiliate
                                    attempt or challenge or assist or allow
                                    others to challenge or to register or to
                                    attempt to register the marks of Company or
                                    LuckyDays (including its group companies).
                                    Provided also that Affiliate shall not
                                    register nor attempt to register any mark or
                                    website domain which is identical or similar
                                    to any mark which belongs to Company or
                                    LuckyDays (including its group companies).
                                 </li>
                              </ol>
                           </li>
                           <li>
                              <span className="small-header">Article 20</span>
                              <h3>Commission Structure:</h3>
                              <p>
                                 Commissions are paid out as a percentage of the
                                 Net Revenue.
                              </p>
                              <p>
                                 For Affiliates which signed up after the 1st
                                 January 2019, the Net Revenue (applying to all
                                 products) is defined as:
                              </p>
                              <p>
                                 Bets – Wins – Admin Fee* – Jackpot Contribution
                                 – Paid Bonuses** – Transaction Costs &amp;
                                 Chargebacks – Applicable Gambling Taxes***
                              </p>
                              <p>
                                 The Net Revenue share percentage is determined
                                 by the amount of new depositing customers
                                 referred by the Affiliate within the month the
                                 commission is paid for.
                              </p>
                              <p>
                                 Number of new depositing customers – Affiliate
                                 share
                              </p>
                              <ul>
                                 <li>0-5 -25%</li>
                                 <li>5-10 -30%</li>
                                 <li>10-20 -35%</li>
                                 <li>20-40 -40%</li>
                                 <li>40+ -45%</li>
                              </ul>
                              <p>
                                 The amount of commission earned when reaching a
                                 tier will apply to all customers, also those
                                 referred to reach that tier.
                              </p>
                           </li>
                           <li>
                              <span className="small-header">Article 21</span>
                              <h3>High Roller Policy:</h3>
                              <ol>
                                 <li>
                                    In any given month, if a Customer generates
                                    a negative net revenue of at least €10,000
                                    he/she will be deemed to be, for the
                                    purposes of this section, a ‘High-Roller’.
                                 </li>
                                 <li>
                                    If the aggregate Net Revenue for the
                                    Affiliate, in that month, for a Merchant
                                    Brand is negative €2,000 or greater, then
                                    the High-Roller policy as set out hereunder,
                                    will apply:
                                 </li>
                                 <li>
                                    The negative Net Revenue generated by the
                                    High-Roller will be carried forward and
                                    offset against future Net Revenue generated
                                    by that High-Roller;
                                 </li>
                                 <li>
                                    The negative balance carried forward cannot
                                    be set-off against other Customers’ Net
                                    Revenue;
                                 </li>
                                 <li>
                                    The negative balance carried forward cannot
                                    be greater than the total aggregate negative
                                    Net Revenue for that particular Brand on the
                                    Affiliate account, for that month;
                                 </li>
                                 <li>
                                    The negative balance of a High-Roller will
                                    be reduced by future positive Net Revenue
                                    that they generate in subsequent months;
                                 </li>
                                 <li>
                                    A negative balance will not be increased by
                                    future negative Net Revenue unless the
                                    High-Roller meets the qualifying criteria in
                                    subsequent months.
                                 </li>
                              </ol>
                              <span className="small-header">Article 22</span>
                              <h3>State of Ontario:</h3>
                              <ol>
                                 <li>
                                    For affiliates that is targeting potential players
                                    that are resident in the state of Ontario in Canada,
                                    please refer to the additional terms & conditions:
                                    {' '}
                                    <a href="/terms-and-conditions-ontario/">
                                       Guidelines for Affiliates on Ontario Gambling
                                       Advertising.
                                    </a>
                                 </li>
                              </ol>
                           </li>
                        </ol>
                        <small>
                           * The admin fee is a value which contains the license
                           fee, game provider fee and platform fee.
                        </small>
                        <small>
                           ** Bonuses in this case means paid bonuses to the
                           customer, not granted bonuses. The difference is that
                           a paid bonus is a type of bonus money where the
                           customer has actually reached the wagering
                           requirement and/or has received the funds on his/her
                           real money wallet.
                        </small>
                        <small>
                           *** The gambling tax is monies paid in the form of
                           betting duties or taxes payable by the operator to
                           the authorities, not including corporate income tax
                           or equivalent. The gambling tax does solely apply to
                           revenues being generated from within the relevant
                           jurisdictions and will be charged at the given rate
                           of taxation of the respective gambling authorities.
                        </small>
                     </div>
                  </Section>
                  {modalVisible && <Modal toggleModal={toggleModal} />}
               </StyledTerms>
            </div>
      </>
   );
};

export default Terms;
