import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
   padding: 32px;
   background: #fff;
   /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05); */
   box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
   border-radius: 8px;
   text-align: center;

   h3 {
      font-size: 20px;
      margin-bottom: 16px;
   }

   p {
      font-size: 16px;
   }
`;

const Card = ({ children }) => {
   return <StyledCard className="card">{children}</StyledCard>;
};

export default Card;
