import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
   background: ${props => props.background};
   padding: 64px 32px;
   display: flex;
   justify-content: center;
   align-items: center;

   @media (min-width: 720px) {
      padding: 86px 32px;
   }

   .content {
      width: 100%;
      max-width: 960px;
   }
`;

const Section = ({ background, children, ...rest }) => {
   return (
      <StyledSection background={background} {...rest}>
         <div className="content">{children}</div>
      </StyledSection>
   );
};

export default Section;
